import React from "react"

import { Container, SEO } from "../components"
import { BackButton } from "../components/shared"

import pic404 from "../../content/images/404.png"

const PageNotFound = () => {
  return (
    <Container title="Page not found">
      <SEO />
      <div className="full">
        <BackButton>
          <img src={pic404} alt="404 - Page Not Found" />
        </BackButton>
      </div>
    </Container>
  )
}

export default PageNotFound
